import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useApiClient } from 'Contexts/ApiClientContext';
import moment from "moment";
import { Button, Card, Loading, SectionTitle, PageHeader, PageSettings, PrinterSelector } from 'Components';
import { Address, MontaOrderEvent, SalesOrder, SalesOrderItem, addressEquals, personalisationTypeEnumToLabel } from "types/sales-order";
import { MontaOrder } from "types/sales-order/montaOrder";
import Accordion from "Components/Library/Accordion";
import Modal from "Components/Library/Modal";
import MontaOrderCard from "Components/SalesOrders/MontaOrder";
import SalesOrderLinesDetail from "Components/SalesOrders/SalesOrderLinesDetail";
import { CancelLineItem } from "utils/apiClient";
import { useAuth0 } from "@auth0/auth0-react";
import { Permissions, useMessage, usePermissions } from 'Contexts';
import StoreLogo from "Components/SalesOrders/StoreLogo";
import OrderEvents from "Components/SalesOrders/OrderEvents";
import ErrorEvents from "Components/ErrorEvents";

export const displayPersoDetails = (line: SalesOrderItem | null) => {
    if (!line || !line.personalisation) return '';

    return `${personalisationTypeEnumToLabel(line.personalisation.type)}: ${line.personalisation.positions.map(pos => pos.name).join(', ')} `;
}

export default function SalesOrderDetail() {
    let { mageReference } = useParams();
    const apiClient = useApiClient();

    const {
        user,
    } = useAuth0();
    const { isAllowed } = usePermissions();
    const { handleError } = useMessage();

    const [salesOrder, setSalesOrder] = useState<SalesOrder | null>(null)
    const [montaOrders, setMontaOrders] = useState<MontaOrder[] | null>(null)
    const [montaOrderEvents, setMontaOrderEvents] = useState<MontaOrderEvent[] | null>(null)
    const [selectedPrinter, setSelectedPrinter] = useState<number | null>(parseInt(localStorage.getItem('selectedInboundBarcodePrinter') ?? ''))
    const [billingSameAsShippingAddress, setBillingSameAsShippingAddress] = useState<boolean>(false);
    const [selectedShippingLabelAddress, setSelectedLabelAddress] = useState<Address | null>(null);
    const [hasShippedMontaOrders, setHasShippedMontaOrders] = useState<boolean>(false);

    const [showEventLog, setShowEventLog] = useState(false);
    const [showErrorLog, setShowErrorLog] = useState(false);
    const [showPrintNewShippingLabel, setShowPrintNewShippingLabel] = useState(false);

    const handleCancellation = async (webshopOrderId: string, reason: string, lineItem?: CancelLineItem) => {
        if (!user) return; // Should not happen
        if (!user.email) {
            console.error("User does not have an email address, cannot cancel line item");
            return;
        }

        const author = user.email;
        const { data } = await apiClient.cancelLineItem(webshopOrderId, author, reason, lineItem);
        if (data) {
            setSalesOrder(data.salesOrder);
            setMontaOrders(data.montaOrders);
            setMontaOrderEvents(data.orderEvents);
        }
    }

    useEffect(() => {
        const fetchSalesOrder = async () => {
            if (!mageReference) return;
            try {
                const { data } = await apiClient.getSalesOrderView(mageReference);
                if (data) {
                    setSalesOrder(data.salesOrder);
                    setMontaOrders(data.montaOrders);
                    setMontaOrderEvents(data.orderEvents);
                    setSelectedLabelAddress(data.salesOrder.shipping.address);
                    setHasShippedMontaOrders(data.montaOrders?.some(mo => mo.shipped));

                    if (addressEquals(data.salesOrder.billing.address, data.salesOrder.shipping.address)) {
                        setBillingSameAsShippingAddress(true);
                    }
                }
            } catch (error) {
                console.error("Error fetching sales order:", error);
            }
        };

        fetchSalesOrder();
    }, [apiClient, mageReference]);

    const handleEventLogClicked = () => {
        setShowEventLog(true);
    };

    const handleErrorLogClicked = () => {
        setShowErrorLog(true);
    };

    const handlePrintNewShippingLabelClicked = () => {
        setShowPrintNewShippingLabel(true);
    }

    const handleEventLogClosed = () => {
        setShowEventLog(false);
    }
    const handleErrorLogClosed = () => {
        setShowErrorLog(false);
    }

    const handlePrintNewShippingLabelClosed = () => {
        setShowPrintNewShippingLabel(false);
    }

    const handlePrintNewShippingLabelSubmit = async () => {
        if (selectedPrinter === null)
        {
            handleError('No printer selected');
            return;
        }
        await apiClient.printNewShippingLabel(salesOrder!.reference.mage, selectedShippingLabelAddress, selectedPrinter);
        handlePrintNewShippingLabelClosed();
    }

    const handleResolveErrorItem = async (errorId: string) => {
        if (!salesOrder) return;
        if (!user) return; // Should not happen
        if (!user.email) {
            console.error("User does not have an email address, cannot resolve");
            return;
        }

        var updatedEvent = await apiClient.resolveSalesOrderErrorEvent(salesOrder.reference.mage, errorId, user.email);
        if (!updatedEvent || updatedEvent == null) return;


        const eventIndex = salesOrder.errorEvents.findIndex(event => event.id === updatedEvent!.id);

        if (eventIndex === -1) return;

        const updatedErrorEvents = [...salesOrder.errorEvents];
        updatedErrorEvents[eventIndex] = updatedEvent;

        // Use setSalesOrder to update the salesOrder state with the new errorEvents array
        setSalesOrder({ ...salesOrder, errorEvents: updatedErrorEvents });
    };

    const handleResolveAllErrors = async () => {
        if (!salesOrder) return;
        if (!user) return; // Should not happen
        if (!user.email) {
            console.error("User does not have an email address, cannot resolve");
            return;
        }

        const updatedSalesOrder = await apiClient.resolveAllSalesOrderErrorEvents(salesOrder.reference.mage, user.email);
        if (!updatedSalesOrder) return;

        setSalesOrder({ ...salesOrder, errorEvents: updatedSalesOrder.errorEvents });
        setShowErrorLog(false);
    }

    if (salesOrder === null) return (
        <Loading />
    )

    const amountOfErrors = salesOrder.errorEvents.filter(e => !e.isResolved).length;

    return (
        <>
            <PageHeader
                title={(
                    <>
                        <div className="flex items-center gap-2">
                            <StoreLogo store={salesOrder.store} />
                            {salesOrder.reference.mage}
                        </div>
                    </>
                )}
                documentTitle={`#${salesOrder.reference.mage}`}
                metaData={[
                    salesOrder.reference.bol && (
                        `Bol: ${salesOrder.reference.bol}`
                    ),
                    `Created: ${moment(salesOrder.meta.createdAt).format('DD-MM-YYYY HH:mm')}`
                ]}
                toolbar={<>
                    {hasShippedMontaOrders && (
                        <Button onClick={handlePrintNewShippingLabelClicked} type="cta">
                            Print New Shipping Label
                        </Button>
                    )}
                    <Button onClick={handleEventLogClicked} type="white">
                        Event Log
                    </Button>
                    <Button onClick={handleErrorLogClicked} type="black">
                        Error Log {amountOfErrors > 0 && `(${amountOfErrors})`}
                    </Button>
                    {hasShippedMontaOrders && <PageSettings>
                        <b>Labelprinter</b>
                        <PrinterSelector
                            storageKey="selectedNewShippingLabelPrinter"
                            selectedPrinter={selectedPrinter ?? 0}
                            setSelectedPrinter={setSelectedPrinter}
                        />
                    </PageSettings>
                    }
                </>}
            />

            {showEventLog && (
                <Modal title="Event Log" onClose={handleEventLogClosed} showBackdrop closeWithEscape>
                    <OrderEvents orderEvents={montaOrderEvents || []} />
                </Modal>
            )}

            {showErrorLog && (
                <Modal title="Error Log" onClose={handleErrorLogClosed} showBackdrop closeWithEscape>
                    <ErrorEvents
                        errorEvents={salesOrder.errorEvents || []}
                        allowedToResolve={isAllowed(Permissions.RESOLVE_ERROR_EVENT_SALES_ORDERS)}
                        resolveErrorEvent={handleResolveErrorItem}
                        resolveAllErrorEvents={handleResolveAllErrors}
                    />
                </Modal>
            )}

            {showPrintNewShippingLabel && (
                <Modal
                    title="Print New Shipping Label"
                    onClose={handlePrintNewShippingLabelClosed}
                    showBackdrop
                    closeWithEscape
                >
                    <div>
                        {!selectedPrinter
                            ? (<p>A printer must be selected through the settings (gear icon)</p>)
                            : billingSameAsShippingAddress
                                ? (<p> Are you sure you want to print a new shipping label?</p>)
                                : (
                                    <div>
                                        <p className="my-1">Which address should be used?</p>
                                        <div className="flex gap-4 text-gray-600 whitespace-nowrap">
                                            <div className="flex-1 py-6 pr-6 pl-5 border border-gray-300 rounded-md flex items-start">
                                                <input
                                                    type="radio"
                                                    id="billingAddress"
                                                    name="addressOption"
                                                    className="mr-3 mt-1.5"
                                                    onClick={() => setSelectedLabelAddress(salesOrder.billing.address)}
                                                />
                                                <label htmlFor="billingAddress" className="cursor-pointer flex-shrink-0">
                                                    <h3 className="font-semibold mb-1 text-gray-800">Billing Address</h3>
                                                    <p className="text-sm">
                                                        {salesOrder.billing.address.street} {salesOrder.billing.address.houseNumber}
                                                        {salesOrder.billing.address.houseNumberAddition ? ` ${salesOrder.billing.address.houseNumberAddition}` : ''}<br />
                                                        {salesOrder.billing.address.postalCode} {salesOrder.billing.address.city}<br />
                                                        {salesOrder.billing.address.countryCode}
                                                    </p>
                                                </label>
                                            </div>
                                            <div className="flex-1 py-6 pr-6 pl-5 border border-gray-300 rounded-md flex items-start">
                                                <input
                                                    type="radio"
                                                    id="shippingAddress"
                                                    name="addressOption"
                                                    className="mr-3 mt-1.5"
                                                    onClick={() => setSelectedLabelAddress(salesOrder.shipping.address)}
                                                    defaultChecked
                                                />
                                                <label htmlFor="shippingAddress" className="cursor-pointer">
                                                    <h3 className="font-semibold mb-1 text-gray-800">Shipping Address</h3>
                                                    <p className="text-sm">
                                                        {salesOrder.shipping.address.street} {salesOrder.shipping.address.houseNumber}
                                                        {salesOrder.shipping.address.houseNumberAddition ? ` ${salesOrder.shipping.address.houseNumberAddition}` : ''}<br />
                                                        {salesOrder.shipping.address.postalCode} {salesOrder.shipping.address.city}<br />
                                                        {salesOrder.shipping.address.countryCode}
                                                    </p>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                )}

                        <div className="flex justify-end gap-4 mt-6">
                            <Button onClick={handlePrintNewShippingLabelClosed} type="white">Cancel</Button>
                            <Button onClick={handlePrintNewShippingLabelSubmit} type="cta">Print</Button>
                        </div>
                    </div>
                </Modal >
            )}

            <div className="flex gap-6">
                <Card className="flex-none w-58 h-[26rem] sticky top-6">
                    <span className="block">Payment Method
                        <span className="block text-gray-600">{salesOrder.billing.paymentMethod}</span>
                    </span>
                    <SectionTitle>Customer</SectionTitle>
                    <div>

                        <span>Name</span>
                        <span className="text-gray-600 block">
                            {salesOrder.customer.firstName} {salesOrder.customer.lastName}
                        </span>

                        <span className="block mt-2">Billing Address</span>
                        <span className="block text-gray-600">{salesOrder.billing.address.street} {salesOrder.billing.address.houseNumber} {salesOrder.billing.address.houseNumberAddition ?? ''} {salesOrder.billing.address.postalCode} {salesOrder.billing.address.city} ({salesOrder.billing.address.countryCode})</span>

                        <span className="block">Shipping Address</span>
                        <span className="block text-gray-600">
                            {salesOrder.shipping.pickupLocationCode && (
                                <span className="block">Service Point: {salesOrder.shipping.pickupLocationCode}</span>
                            )}
                            {salesOrder.shipping.address.street} {salesOrder.shipping.address.houseNumber} {salesOrder.shipping.address.houseNumberAddition ?? ''} {salesOrder.shipping.address.postalCode} {salesOrder.shipping.address.city} ({salesOrder.shipping.address.countryCode})
                        </span>

                        <span className="block mt-2">Email</span>
                        <span className="text-gray-600 block"> {salesOrder.customer.email}</span>

                        <span>Phone number</span>
                        <span className="text-gray-600 block"> {salesOrder.customer.phoneNumber}</span>
                    </div>
                </Card>

                <div className="flex flex-col w-full gap-y-6">
                    <Accordion title="Order Lines" openByDefault>
                        <SalesOrderLinesDetail salesOrder={salesOrder} />
                    </Accordion>

                    <Accordion title="Monta fulfillments" openByDefault>
                        <div className="space-y-4">
                            {montaOrders?.map((montaOrder, index) => (
                                <div key={index}>
                                    <MontaOrderCard salesOrder={salesOrder} order={montaOrder} handleCancellation={handleCancellation} />
                                </div>
                            ))}

                            {montaOrders?.length === 0 && (
                                <div>
                                    No Monta fulfillments found
                                </div>
                            )}
                        </div>
                    </Accordion>
                </div>

            </div >
        </>
    );
}
