import { useParams } from "react-router";
import { useApiClient } from 'Contexts/ApiClientContext';
import { useMessage } from "Contexts";
import { useEffect, useState } from "react";
import { MontaOrder } from "types/sales-order/montaOrder";
import { InboundForecastGroup } from "types/inbound-forecast-group";
import { Stock } from "types/stock";
import { PageHeader, Card, Loading } from "Components";
import { useClickableNavigate } from "utils/PathConstants";

export default function InventoryDetail() {
    let { sku } = useParams();
    const apiClient = useApiClient();
    const { handleError } = useMessage();

    const [stock, setStock] = useState<Stock | null>(null);
    const [supplierName, setSupplierName] = useState<string | null>(null);
    const [montaOrders, setMontaOrders] = useState<MontaOrder[]>([]);
    const [inbounds, setInbounds] = useState<InboundForecastGroup[]>([]);
    const [stockLoading, setStockLoading] = useState(true);
    const [montaOrdersLoading, setMontaOrdersLoading] = useState(true);
    const [inboundsLoading, setInboundsLoading] = useState(true);

    const {
        onMouseDownHandler,
        onMouseMoveHandler,
        navigateToSalesOrder,
        navigateToInboundForecastGroup,
    } = useClickableNavigate();

    useEffect(() => {
        const fetchData = async () => {
            if (!sku) {
                handleError('SKU is required');
                return;
            }

            // Get Stock and Supplier
            const stock = await apiClient.getStockBySku(sku);
            setStock(stock);
            setStockLoading(false);

            // Handle the case where stock is not found
            if (!stock) {
                handleError('Stock not found');
                return;
            }

            // Fetch supplier information if stock is found
            const supplier = await apiClient.getSupplierByCode(stock.product.supplierMontaCode);
            setSupplierName(supplier?.data?.name || 'Supplier not found');

            // Fetch Monta Orders
            const montaOrders = await apiClient.getOpenMontaOrdersBySku(sku);
            setMontaOrders(montaOrders);
            setMontaOrdersLoading(false);

            // Fetch Inbound Forecast Groups
            const inbounds = await apiClient.getOpenInboundForecastGroupsBySku(sku);
            setInbounds(inbounds);
            setInboundsLoading(false);
        };

        fetchData();
    }, [apiClient, handleError, sku]);

    if (!stock) {
        return stockLoading
            ? <Loading />
            : <></>;
    }

    return (
        <>
            <PageHeader title="Inventory Detail" />

            {/* Stock Summary */}
            <Card className="mb-6">
                <div className="grid grid-cols-2 gap-6">
                    <div>
                        <h2 className="text-xl font-bold text-gray-900 mb-4">Stock Summary</h2>
                        <div className="grid grid-cols-2 gap-2">
                            {[
                                { label: 'Name', value: stock.product.name },
                                { label: 'Supplier', value: supplierName },
                                { label: 'Sku', value: stock.product.sku },
                                { label: 'Ean', value: stock.product.ean },
                            ].map((item, index) => (
                                <div key={index} className="flex flex-col">
                                    <p className="text-gray-500 text-sm">{item.label}</p>
                                    <p className="text-lg font-semibold text-gray-800">{item.value}</p>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div>
                        <div className="grid grid-cols-2 gap-4">
                            {[
                                { label: 'All', value: stock.amounts.all },
                                { label: 'Warehouse', value: stock.amounts.warehouse },
                                { label: 'Available', value: stock.amounts.available },
                                { label: 'Reserved', value: stock.amounts.reserved },
                                { label: 'Forecasted', value: stock.amounts.forecasted },
                                { label: 'Step', value: stock.amounts.step },
                            ].map((item, index) => (
                                <div key={index} className="flex flex-col">
                                    <p className="text-gray-500 text-sm">{item.label}</p>
                                    <p className="text-lg font-semibold text-gray-800">{item.value}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </Card>

            {/* Full-Height Monta Orders and Inbound Forecast Groups */}
            <div className="grid grid-cols-2 gap-6">
                {/* Monta Orders */}
                <div>
                    <Card>
                        <div className="flex justify-between mb-4">
                            <h2 className="text-xl font-semibold text-gray-900">Monta Orders</h2>
                            <p className="text-lg">
                            <span className="text-gray-500"> Total in all open Monta Orders: </span>
                                <span className="text-gray-900 font-semibold">{
                                    montaOrders
                                        .reduce((totalQuantity, montaOrder) =>
                                            totalQuantity + montaOrder.lines
                                                .filter(line => line.sku === sku)
                                                .reduce((orderQuanity, line) => orderQuanity + line.orderedQuantity, 0),
                                            0
                                        )
                                }
                                </span>
                            </p>
                        </div>
                        <div className="space-y-4">
                            {montaOrdersLoading
                                ? <Loading />
                                : montaOrders.length > 0 ? (
                                    montaOrders.map((montaOrder, index) => (
                                        <div
                                            key={index}
                                            onMouseDown={onMouseDownHandler}
                                            onMouseMove={onMouseMoveHandler}
                                            onClick={(e) => navigateToSalesOrder(e, montaOrder.webshopOrderId)}
                                            className="cursor-pointer p-4 hover:bg-gray-200 rounded-lg border border-gray-200 transition-all"
                                        >
                                            <div className="flex justify-between items-center">
                                                <p className="font-semibold text-lg text-gray-800">{montaOrder.webshopOrderId}</p>
                                                <p className="text-sm text-gray-500">Quantity: {
                                                    montaOrder.lines.filter(line => line.sku === sku).reduce((orderQuanity, line) => orderQuanity + line.orderedQuantity, 0)
                                                }</p>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <p className="text-gray-500">No Monta Orders available.</p>
                                )}
                        </div>
                    </Card>
                </div>

                {/* Inbound Forecast Groups */}
                <div>
                    <Card>
                        <div className="flex justify-between mb-4">
                            <h2 className="text-xl font-semibold text-gray-900">Inbound Forecast Groups</h2>
                            <p className="text-lg">
                            <span className="text-gray-500">Total in all open Inbounds: </span>
                                <span className="text-gray-900 font-semibold">{
                                    inbounds
                                        .reduce((totalQuantity, inbound) =>
                                            totalQuantity + inbound.inboundForecasts
                                                .filter(line => line.sku === sku)
                                                .reduce((acc, line) => acc + line.quantity, 0),
                                            0
                                        )
                                }
                                </span>
                            </p>
                        </div>
                        <div className="space-y-4">
                            {inboundsLoading
                                ? <Loading />
                                : inbounds.length > 0 ? (
                                    inbounds.map((inbound, index) => (
                                        <div
                                            key={index}
                                            onClick={(e) => navigateToInboundForecastGroup(e, inbound.purchaseOrderReference)}
                                            className="cursor-pointer p-4 hover:bg-gray-200 rounded-lg border border-gray-200 transition-all"
                                        >
                                            <div className="flex justify-between items-center">
                                                <p className="font-semibold text-lg text-gray-800">{inbound.purchaseOrderReference}</p>
                                                <p className="text-sm text-gray-500">Quantity: {
                                                    inbound.inboundForecasts.filter(line => line.sku === sku).reduce((acc, line) => acc + line.quantity, 0)
                                                }</p>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <p className="text-gray-500">No Inbound Forecast Groups available.</p>
                                )}
                        </div>
                    </Card>
                </div>
            </div>
        </>
    );
}
